import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

import { FindingsByImage } from "@kells/interfaces/finding";
import { SubSink } from "subsink";
import { KellsImageBase } from "@kells/interfaces/image";
import { DataAccessService } from "@kells/apis/data-access";
import { ImageType, Patient } from "@kells/clinic-one/apis";
import { cariesInitialFocus_altBright } from "../theme/colors";

import {
  rotateInOnEnterAnimation,
  rotateInUpLeftOnEnterAnimation,
  rotateInUpRightOnEnterAnimation,
  rotateInDownLeftOnEnterAnimation,
  rotateInDownRightOnEnterAnimation,
  rotateOutOnLeaveAnimation,
  rotateOutUpLeftOnLeaveAnimation,
  rotateOutUpRightOnLeaveAnimation,
  rotateOutDownLeftOnLeaveAnimation,
  rotateOutDownRightOnLeaveAnimation,
  slideInRightOnEnterAnimation,
  slideInUpOnEnterAnimation,
  slideInDownOnEnterAnimation,
  slideInLeftOnEnterAnimation,
  slideOutUpOnLeaveAnimation,
  slideOutDownOnLeaveAnimation,
  slideOutLeftOnLeaveAnimation,
  slideOutRightOnLeaveAnimation,
} from "angular-animations";
import { DownloadFileModel } from "../../models/file.model";
import {
  distinctUntilDeepValueChanged,
  keepDefined,
} from "@kells/utils/observable/observable-operators";

// export const INITIAL_TO_MODERATE = ['#7b8ccc', '#918db7', '#a38ea1', '#b18f89', '#bb9175', '#c6925a', '#cf9440', '#d59620'];
// export const INITIAL_TO_ADVANCED = ['#7b8ccc', '#8a8ac2', '#9787b8', '#a285ae', '#ac82a5', '#b57f9b', '#bd7d91', '#c47a87', '#cb777e', '#d27474'];
// export const INITIAL_ONLY = ['#35557D', '#3A5B85', '#3F628E', '#456896', '#4B6F9E', '#5075A6', '#567CAF', '#5C83B7', '#6389BF', '#6990C7'];

// export const MODERATE_FROM_INITAL = ['#ddd1c8', '#f2d7a6'];
// export const MODERATE_ONLY = ['#90561E', '#995F1F', '#A36820', '#AC7121', '#B57A23', '#BE8324', '#C78D25', '#CF9726', '#D7A127', '#DFAB28'];

// export const ADVANCED = ['#741413', '#831F1A', '#922A22', '#A0362B', '#AD4334', '#B9503F', '#C45E4A', '#CE6D56', '#D77C63', '#DF8B71'];

export interface OnDownloadParams {
  images: DownloadFileModel[];
  imageType: ImageType;
}

@Component({
  selector: "koa-xray-carousel",
  templateUrl: "./xray-carousel.component.html",
  styleUrls: ["./xray-carousel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    rotateInOnEnterAnimation({ anchor: "enablePrevImage", duration: 111 }),
    rotateInUpLeftOnEnterAnimation(),
    rotateInUpRightOnEnterAnimation(),
    rotateInDownLeftOnEnterAnimation(),
    rotateInDownRightOnEnterAnimation(),
    rotateOutOnLeaveAnimation({ anchor: "disablePrevImage", duration: 111 }),
    rotateOutUpLeftOnLeaveAnimation(),
    rotateOutUpRightOnLeaveAnimation(),
    rotateOutDownLeftOnLeaveAnimation(),
    rotateOutDownRightOnLeaveAnimation(),
    slideInRightOnEnterAnimation(),
    slideInUpOnEnterAnimation(),
    slideInDownOnEnterAnimation(),
    slideInLeftOnEnterAnimation(),
    slideOutUpOnLeaveAnimation(),
    slideOutDownOnLeaveAnimation(),
    slideOutLeftOnLeaveAnimation(),
    slideOutRightOnLeaveAnimation(),
  ],
})
export class XRayCarouselComponent implements OnInit {
  private _subs = new SubSink();
  // images: KellsImageBase[] = [];
  images = new BehaviorSubject<Map<string, FindingsByImage>>(
    new Map<string, FindingsByImage>()
  );

  public readonly images$ = this.images.asObservable().pipe(
    keepDefined(),
    distinctUntilDeepValueChanged(),
    map((images) => Array.from(images.values()))
  );

  public readonly imagesLength$ = this.images$.pipe(
    map((images) => images.length)
  );

  public readonly isImagesEmpty$ = this.imagesLength$.pipe(
    map((xrayImagesLength) => xrayImagesLength === 0)
  );

  //observeProperty(this, 'images').pipe(keepDefined(), distinctUntilKeyChanged('length'), pipeLog('tmpImages'));
  isShowXrays = true;
  xrayBtnText = "HIDE X-RAYS";
  showBtnText = "VIEW X-RAYS";
  hideBtnText = "HIDE X-RAYS";
  hostWidth: string;
  hostHeight: string;
  mainPalette = cariesInitialFocus_altBright;
  selectedPatient: Patient;

  constructor(public data: DataAccessService) {}

  cavityXrayStartIndex = 0;
  cavityXrayEndIndex = 100;

  @Input("title") title = "";
  @Input("selectedSession") selectedSession: string;

  @Input("hideIfNoFindingsForImage") hideIfNoFindingsForImage = false;
  @Input("showFindings") showFindings = true;
  @Input("showCaries") showCaries = true;
  @Input("showBoneloss") showBoneloss = true;
  @Input("showPlaque") showPlaque = false;
  @Input("showFracture") showFracture = false;
  @Input("showGumRecession") showGumRecession = false;
  @Input("showGumInflammation") showGumInflammation = false;
  @Input("showMissingTooth") showMissingTooth = false;
  @Input("interactive") interactive = true;
  @Input("showToggleXrayButton") showToggleXrayButton = false;
  @Input("downloadEnabled") downloadEnabled = false;
  @Input("imageType") imageType: ImageType = ImageType.Xray;

  @Output("onDownload")
  downloadXraysEvent = new EventEmitter<OnDownloadParams>();
  @Output("onXrayOpen") xrayOpenEvent = new EventEmitter<void>();

  ngOnInit() {
    this._subs.sink = this.data.selectedPatient$.subscribe(
      (patient) => (this.selectedPatient = patient)
    );

    this._subs.sink = this.data.reportDataLoadComplete$.subscribe(
      ({ report, selectedSession, findingsByTooth, findingsByImage }) => {
        const imageData = new Map<string, FindingsByImage>();

        let counter = 0;

        findingsByImage!.forEach((x) => {
          if (x.imageType !== this.imageType) return;

          if (this.showCaries) {
            if (
              x.image.findings.filter((c) => c.type === "caries").length > 0
            ) {
              imageData.set(counter.toString(), x);
              counter++;
              return;
            }
          }
          if (this.showBoneloss) {
            if (
              x.image.findings.filter(
                (c) =>
                  c.type === "bone_loss" &&
                  Number(c.bone_loss_attributes?.measurement_mm) >= 3
              ).length > 0
            ) {
              imageData.set(counter.toString(), x);
              counter++;
              return;
            }
          }
          if (this.showPlaque) {
            if (
              x.image.findings.filter((c) => c.type === "plaque").length > 0
            ) {
              imageData.set(counter.toString(), x);
              counter++;
              return;
            }
          }
          if (this.showFracture) {
            if (
              x.image.findings.filter((c) => c.type === "fracture").length > 0
            ) {
              imageData.set(counter.toString(), x);
              counter++;
              return;
            }
          }
          if (this.showGumRecession) {
            if (
              x.image.findings.filter((c) => c.type === "gum_recession")
                .length > 0
            ) {
              imageData.set(counter.toString(), x);
              counter++;
              return;
            }
          }
          if (this.showGumInflammation) {
            if (
              x.image.findings.filter((c) => c.type === "gum_inflammation")
                .length > 0
            ) {
              imageData.set(counter.toString(), x);
              counter++;
              return;
            }
          }
          if (this.showMissingTooth) {
            if (
              x.image.findings.filter((c) => c.type === "missing_tooth")
                .length > 0
            ) {
              imageData.set(counter.toString(), x);
              counter++;
              return;
            }
          }
          if (this.hideIfNoFindingsForImage) {
            return;
          }
          imageData.set(counter.toString(), x);
          counter++;
        });

        this.images.next(imageData as Map<string, FindingsByImage>);
      }
    );
  }

  toggleBtnValue(toggleValue: boolean) {
    this.isShowXrays = !toggleValue;
    this.xrayBtnText = this.isShowXrays ? this.hideBtnText : this.showBtnText;
  }

  prevXRays = () => {
    const len = this.images.value.size;
    if (0 < this.cavityXrayStartIndex) {
      this.cavityXrayStartIndex = Math.max(
        this.cavityXrayStartIndex - this.cavityXrayEndIndex,
        0
      );
      this.cavityXrayEndIndex = Math.min(
        this.cavityXrayStartIndex + this.cavityXrayEndIndex,
        len
      );
    }
  };

  nextXRays = () => {
    const len = this.images.value.size;

    if (len > this.cavityXrayEndIndex) {
      this.cavityXrayEndIndex = Math.min(
        this.cavityXrayEndIndex + this.cavityXrayEndIndex,
        len
      );
      this.cavityXrayStartIndex = Math.max(
        this.cavityXrayStartIndex + this.cavityXrayEndIndex,
        0
      );
    }
  };

  trackImageIdentityByUrl(index: number, item: KellsImageBase) {
    return item.url;
  }

  downloadXrays() {
    const images = Array.from(this.images.getValue().values());
    const imagesData = images.map((image) => ({
      includeFileExtension: true,
      downloadUrl: image.image_url,
      fileName: image.name,
    }));

    this.downloadXraysEvent.emit({
      images: imagesData,
      imageType: this.imageType,
    });
  }
}
