import { DATA_HIDDEN_CONFIG } from "../constants/data-hidden-config.constants";
import {
  DataHiddenSourceFields,
  HiddenData,
} from "../models/hidden-data.models";
import { Patient } from "@kells/clinic-one/apis";

export const checkIsDataHidden = (
  patient: Patient & { organizations: string[] },
  sourceFields: DataHiddenSourceFields
): boolean => {
  const { insurance_provider, organizations } = patient;

  const isHiddenForOrganization = sourceFields.organizations.some(
    (organization) =>
      organizations
        .map((org) => org.trim().toLowerCase())
        .includes(organization.trim().toLowerCase())
  );

  const isHiddenForInsurance = sourceFields.insurances.some(
    (insurance) =>
      insurance.trim().toLowerCase() ===
      insurance_provider?.trim().toLowerCase()
  );

  return isHiddenForOrganization || isHiddenForInsurance;
};

export const isDataHidden = (
  patient: Patient & { organizations: string[] }
): HiddenData => ({
  zocDoc: checkIsDataHidden(patient, DATA_HIDDEN_CONFIG.ZOC_DOC),
  estimatedCosts: checkIsDataHidden(
    patient,
    DATA_HIDDEN_CONFIG.ESTIMATED_COSTS
  ),
  treatments: checkIsDataHidden(patient, DATA_HIDDEN_CONFIG.TREATMENTS),
  talkToADentist: checkIsDataHidden(
    patient,
    DATA_HIDDEN_CONFIG.TALK_TO_A_DENTIST
  ),
  productRecommendations: checkIsDataHidden(
    patient,
    DATA_HIDDEN_CONFIG.PRODUCT_RECOMMENDATIONS
  ),
  dentalAdvisor: checkIsDataHidden(patient, DATA_HIDDEN_CONFIG.DENTAL_ADVISOR),
});
