import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
  CLINIC_ONE_API_BASE_URL,
  CLINIC_ONE_API_VER,
} from "@kells/clinic-one/environments";
import {
  PatientFeatues,
  PaymentLinkRequest,
  PaymentLinkRequestResponse,
  PATIENT_FEATURES_BY_PLAN,
  Product,
  ProductResponse,
  SubscriptionPlan,
  FreeScans,
  FreeScansResponse,
} from "@kells/interfaces/patient";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class MembershipService {
  productsBaseUrl = `${this.baseUrl}/${this.apiVersion}/products`;

  constructor(
    @Inject(CLINIC_ONE_API_BASE_URL) private baseUrl: string,
    @Inject(CLINIC_ONE_API_VER) private apiVersion: string,
    private http: HttpClient
  ) {}

  getProducts(): Observable<Product[]> {
    return this.http
      .get<ProductResponse>(`${this.productsBaseUrl}`)
      .pipe(map((res) => res.data.products));
  }

  getProductPaymentLink(body: PaymentLinkRequest): Observable<string> {
    return this.http
      .post<PaymentLinkRequestResponse>(
        `${this.productsBaseUrl}/payment-link`,
        body
      )
      .pipe(map((res) => res.data.url));
  }

  getProductsFreeScans(): Observable<FreeScans> {
    return this.http
      .get<FreeScansResponse>(`${this.productsBaseUrl}/free-scans`)
      .pipe(map((res) => res.data));
  }

  static isFeatureAvailable(
    feature: PatientFeatues,
    plan: SubscriptionPlan
  ): boolean {
    if (plan === SubscriptionPlan.Basic) {
      const feautrePlan = PATIENT_FEATURES_BY_PLAN[feature];

      return feautrePlan === SubscriptionPlan.Basic;
    }

    // Feature is available if Premium plan
    return true;
  }
}
