import { createEventsFromSchematics, eventDetail } from "@kells/apis/analytics";

interface OpenReportEventInterface {
  patientId: string;
  sessionDate: string;
}

interface OpenProductLinkEventInterface {
  product: string;
  productLink: string;
}

enum EventTypes {
  HomePageOpened = "Home page has been opened",

  OpenAdvisor = "Dental Advisor opened",

  OpenSecondOpinion = "Second Opinion opened",

  NavigateToPaymentLink = "Navigate to Stripe Payment Link [Dashboard]",

  OpenPaymentSuccessModal = "Payment success modal opened",

  OpenAiPhotoScan = "Ai dental Scan opened",

  OpenTalkToDentist = "Confirmation modal for Talk To Dentist opened",
  ConfirmTalkToDentist = "Talk To Dentist open confirmed",
  CloseTalkToDentist = "Confirmation modal for Talk To Dentist cancelled",

  OpenReport = "Report is opened",

  OpenProductRecommendation = "Product recommendation is opened",

  InfoCollectionOpened = "Info collection modal opened [Info Collection]",
  InfoCollectionCompleted = "Info collection modal completed [Info Collection]",

  OnboardingOpened = "Onboarding has been opened [Onboarding]",
  ManualOnboardingTriggered = "Onboarding has been triggered manually [Onboarding]",
  OnboardingStarted = "Onboarding process started [Onboarding]",
  OnboardingReverted = "Onboarding process reverted [Onboarding]",
  OnboardingNavigateForward = "Onboarding navigated forward [Onboarding]",
  OnboardingNavigateBackward = "Onboarding navigated backward [Onboarding]",
  OnboardingNavigateToSlide = "Onboarding navigated to slide [Onboarding]",
  OnboardingClosed = "Onboarding has been closed [Onboarding]",
  OnboardingFinalized = "Onboarding has been completed [Onboarding]",

  OpenProductLinkEvent = "Product link opened [Products Recommendation]",
}

const eventCreators = createEventsFromSchematics({
  HomePageOpenedEvent: {
    displayedName: EventTypes.HomePageOpened,
  },

  OpenAdvisorEvent: {
    displayedName: EventTypes.OpenAdvisor,
  },

  OpenSecondOpinionEvent: {
    displayedName: EventTypes.OpenSecondOpinion,
  },

  NavigateToPaymentLink: {
    displayedName: EventTypes.NavigateToPaymentLink,
  },

  OpenPaymentSuccessModal: {
    displayedName: EventTypes.OpenPaymentSuccessModal,
  },

  OpenAiPhotoScanEvent: {
    displayedName: EventTypes.OpenAiPhotoScan,
  },

  OpenTalkToDentistEvent: {
    displayedName: EventTypes.OpenTalkToDentist,
  },
  ConfirmTalkToDentistEvent: {
    displayedName: EventTypes.ConfirmTalkToDentist,
  },
  CloseTalkToDentistEvent: {
    displayedName: EventTypes.CloseTalkToDentist,
  },

  OpenReportEvent: {
    displayedName: EventTypes.OpenReport,
    detail: eventDetail<OpenReportEventInterface>(),
  },

  OpenProductLinkEvent: {
    displayedName: EventTypes.OpenProductLinkEvent,
    detail: eventDetail<OpenProductLinkEventInterface>(),
  },

  OpenProductRecommendationEvent: {
    displayedName: EventTypes.OpenProductRecommendation,
  },

  InfoCollectionOpenedEvent: {
    displayedName: EventTypes.InfoCollectionOpened,
  },
  InfoCollectionCompletedEvent: {
    displayedName: EventTypes.InfoCollectionCompleted,
  },

  OnboardingOpenedEvent: {
    displayedName: EventTypes.OnboardingOpened,
  },
  ManualOnboardingTriggeredEvent: {
    displayedName: EventTypes.ManualOnboardingTriggered,
  },
  OnboardingStartedEvent: {
    displayedName: EventTypes.OnboardingStarted,
  },
  OnboardingRevertedEvent: {
    displayedName: EventTypes.OnboardingReverted,
  },
  OnboardingNavigateForwardEvent: {
    displayedName: EventTypes.OnboardingNavigateForward,
  },
  OnboardingNavigateBackwardEvent: {
    displayedName: EventTypes.OnboardingNavigateBackward,
  },
  OnboardingNavigateToSlideEvent: {
    displayedName: EventTypes.OnboardingNavigateToSlide,
    detail: eventDetail<number>(),
  },
  OnboardingClosedEvent: {
    displayedName: EventTypes.OnboardingClosed,
  },
  OnboardingFinalizedEvent: {
    displayedName: EventTypes.OnboardingFinalized,
  },
});

export const {
  HomePageOpenedEvent,
  OpenAdvisorEvent,
  OpenSecondOpinionEvent,
  OpenAiPhotoScanEvent,
  OpenTalkToDentistEvent,
  ConfirmTalkToDentistEvent,
  CloseTalkToDentistEvent,
  OpenReportEvent,
  OpenProductRecommendationEvent,
  InfoCollectionOpenedEvent,
  InfoCollectionCompletedEvent,
  NavigateToPaymentLink,
  OnboardingOpenedEvent,
  ManualOnboardingTriggeredEvent,
  OnboardingStartedEvent,
  OnboardingRevertedEvent,
  OnboardingNavigateForwardEvent,
  OnboardingNavigateBackwardEvent,
  OpenPaymentSuccessModal,
  OnboardingNavigateToSlideEvent,
  OnboardingClosedEvent,
  OnboardingFinalizedEvent,
  OpenProductLinkEvent,
} = eventCreators;
