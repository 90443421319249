import {
  bounceInOnEnterAnimation,
  bounceOutOnLeaveAnimation,
  bounceInUpOnEnterAnimation,
  bounceInRightOnEnterAnimation,
  fadeInUpOnEnterAnimation,
  fadeInDownOnEnterAnimation,
  fadeOutDownOnLeaveAnimation,
  zoomInOnEnterAnimation,
  zoomInUpOnEnterAnimation,
  expandOnEnterAnimation,
  collapseOnLeaveAnimation,
  fadeInExpandOnEnterAnimation,
  fadeOutCollapseOnLeaveAnimation,
  expandRightOnEnterAnimation,
  collapseLeftOnLeaveAnimation,
  fadeInExpandRightOnEnterAnimation,
  fadeOutCollapseLeftOnLeaveAnimation,
} from "angular-animations";
import { SharedService } from "apps/patient-reporting/src/app/shared/shared.service";
import { DomSanitizer } from "@angular/platform-browser";
import { VideoDialogComponent } from "./../../../../../../../apps/clinic-one/src/app/shared/components/video-modal/video-dialog.component";

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import {
  Image,
  ImageType,
  PatientRecommendationRiskLevels,
  PatientService,
  RiskFactor,
  TREATMENT_PRIORITY_BY_TYPE,
  Treatment,
  TreatmentService,
  TreatmentTypes,
  WHOLE_MOUTH_TREATMENT_LABEL,
} from "@kells/clinic-one/apis";
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  from,
  fromEvent,
  Observable,
  of,
  Subject,
} from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
  delay,
  startWith,
  take,
  debounceTime,
  catchError,
} from "rxjs/operators";
import { KellsPatient } from "@kells/interfaces/patient";
import { observeProperty } from "@kells/utils/angular";
import { AnalyticsService } from "@kells/apis/analytics";
import * as ReportEvents from "apps/patient-reporting/src/app/shared/models/analytics/report-page.event";
import { pick, uniqBy } from "lodash-es";
import { RiskFactorService } from "../../services/risk-factor.service";
import {
  distinctUntilDeepValueChanged,
  keepDefined,
  pipeLog,
  takeLatest,
} from "@kells/utils/observable/observable-operators";
import { DatePipe, DOCUMENT, ViewportScroller } from "@angular/common";

import { DataAccessService } from "@kells/apis/data-access";
import {
  CariesStageTypes,
  FindingType,
  FindingTypeLabels,
  FindingsByImage,
  FindingsByTooth,
  IndexedFindingsMap,
  KellsFinding,
  RenderableFindingBase,
} from "@kells/interfaces/finding";
import { KellsImageBase } from "@kells/interfaces/image";

import { capitalize, isDefined, isNullable } from "@kells/utils/js";
import { PatientOralHealthCondition } from "../../models/oral-health-condition.model";
import { ReportService } from "@kells/oral-analytics/report";
import { isB2COffice } from "@kells/clinic-one/data-access/office";
import { SubSink } from "subsink";
import {
  trigger,
  style,
  state,
  animate,
  transition,
  query,
  animateChild,
  group,
} from "@angular/animations";
import { ToothService } from "@kells/interfaces/tooth";

import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
} from "angular-animations";

import { gsap } from "gsap";
import { ExpoScaleEase, RoughEase, SlowMo } from "gsap/EasePack";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { Draggable } from "gsap/Draggable";
import { TextPlugin } from "gsap/TextPlugin";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { select, Store } from "@ngrx/store";
import { updatePatientRecommendation } from "libs/oral-analytics/dashboard/src/lib/store/patient-recommendations/patient-recommendations.actions";

import { fromOralAnalytics } from "@kells/oral-analytics/dashboard";
import { MatDialog } from "@angular/material/dialog";
import {
  toBlob,
  toSvg,
  toBlobByChunks,
  Options as HtmlToImageOptions,
} from "html-to-image";
import { isEmpty } from "lodash-es";
import bowser from "bowser";
import { ZocDocService } from "../../services/zocdoc.service";
import { FileBlobData } from "../../models/file.model";
import { TreatmentPlanTableColumnNames } from "../../enums/treatment-plan-table-column-names.enum";
import { TREATMENT_PLAN_TABLE_COLUMNS } from "../../constants/treatment-plan-table-columns.constants";
import { isDataHidden } from "../../utils/data-hidden.utils";
import { OnDownloadParams } from "../../components/xray-carousel/xray-carousel.component";
import { OrganizationNames } from "../../enums/organization-names.enum";
import { ORGANIZATION_CONTENT_SETTINGS } from "../../constants/organization-content-settings.constants";
import { FileService } from "@kells/apis/data-access";
import { ConfirmationModalComponent } from "../../components/confirmation-modal/confirmation-modal.component";
import { Portals } from "../../enums/query.enums";

// const fadeAppear = [
//   trigger("fadeAppear", [
//     transition(":enter", [
//       style({ opacity: 0, transform: "scale(1.2)" }),
//       animate(ANI_ENTER_TIMING, style({ opacity: 1, transform: "scale(1)" })),
//       animateChild(),
//     ]), // void => *
//     transition(":leave", [
//       style({ opacity: 1, transform: "scale(1)" }),
//       animate(ANI_ENTER_TIMING, style({ opacity: 0, transform: "scale(1.1)" })),
//       animateChild(),
//     ]),
//   ]),
// ];

const ENTER_TIMING = "148ms cubic-bezier(.49, .03, .5, .98)";
const LEAVE_TIMING = "148ms cubic-bezier(.49, .03, .5, .98)";

const browser = bowser.parse(window.navigator.userAgent);

@Component({
  selector: "koa-patient-report-page",
  templateUrl: "./punch-patient-report-page.component.html",
  styleUrls: ["./punch-patient-report-page.component.scss"],
  animations: [
    bounceInRightOnEnterAnimation({ anchor: "enter1" }),
    bounceInRightOnEnterAnimation({ anchor: "enter2", delay: 100 }),
    bounceInRightOnEnterAnimation({
      anchor: "enter3",
      delay: 200,
      animateChildren: "none",
    }),

    bounceInOnEnterAnimation({ anchor: "enterBounce1", duration: 222 }),
    bounceInUpOnEnterAnimation(),
    bounceOutOnLeaveAnimation({ anchor: "leaveBounce1", duration: 222 }),

    fadeInOnEnterAnimation(),
    fadeOutOnLeaveAnimation(),

    fadeInUpOnEnterAnimation(),
    fadeInDownOnEnterAnimation({
      anchor: "loading1EnterFadeInDown",
      translate: "1rem",
      duration: 85,
    }),
    fadeOutDownOnLeaveAnimation({
      anchor: "leaveLoading1FadeOutDown",
      translate: "1rem",
      duration: 85,
    }),
    fadeInDownOnEnterAnimation({
      anchor: "loaded1Enter",
      translate: "1rem",
      delay: 85,
    }),

    // flipInXOnEnterAnimation({ anchor: 'enterFlipX1' }),
    // flipInYOnEnterAnimation({anchor:'enterFlipY1'}),
    // flipOutXOnLeaveAnimation({ anchor: 'leaveFlipX1' }),
    // flipOutYOnLeaveAnimation({ anchor: 'leaveFlipY1' }),

    zoomInOnEnterAnimation({
      anchor: "iconLoadingEnter",
      duration: 75,
      delay: 0,
    }),
    zoomInUpOnEnterAnimation({
      anchor: "iconLoadingLeave",
      duration: 75,
      delay: 0,
    }),

    expandOnEnterAnimation({ duration: 400 }),
    collapseOnLeaveAnimation({ duration: 400 }),
    fadeInExpandOnEnterAnimation({ duration: 400 }),
    fadeOutCollapseOnLeaveAnimation({ duration: 400 }),
    expandRightOnEnterAnimation({ duration: 400 }),
    collapseLeftOnLeaveAnimation({ duration: 400 }),
    fadeInExpandRightOnEnterAnimation({ duration: 400 }),
    fadeOutCollapseLeftOnLeaveAnimation({ duration: 400 }),

    trigger("pageAnimations", [
      transition(":enter, :leave", [
        query("@*", [animateChild()], { optional: true }),
      ]),
    ]),
    trigger("fadeBackground", [
      //state(':leave',)),
      //state(':enter',)),
      transition(":enter", [
        style({
          background: "#ecf1ff",
        }),
        group([
          animate(ENTER_TIMING, style({ backgroundColor: "#b2ebf2" })),
          query("@*", [animateChild()], { optional: true }),
        ]),
      ]),
      transition(":leave", [
        style({ background: "#D6FFF5ff" }),
        group([
          animate(LEAVE_TIMING, style({ background: "#f4f4f5" })),
          query("@*", [animateChild()], { optional: true }),
        ]),
      ]),
    ]),

    trigger("revealTitle", [
      state(":enter", style({ opacity: 1, transform: "translateY(0)" })),
      state(":leave", style({ opacity: 0, transform: "translateY(-5px)" })),
      transition("* => *", animate("300ms ease-out")),
    ]),
    trigger("loadComplete", [
      transition(":enter", [
        style({
          opacity: 0,
          transform: "translateY(-5px)",
          position: "absolute",
        }),
        animate(
          "200ms",
          style({
            opacity: 1,
            transform: "translateY(0)",
            position: "relative",
          })
        ),
      ]),
    ]),
  ],
})
export class PatientReportPageComponent implements OnInit, OnDestroy {
  SNAPSHOT_SECTION_ID = "snapshot";
  CAVITIES_SECTION_ID = "cavities";
  BONE_HEALTH_SECTION_ID = "bonehealth";
  SMILE_CHECKLIST_SECTION_ID = "smilechecklist";
  QUESTIONS_SECTION_ID = "questions-section";
  FIND_A_DENTIST_SECTION_ID = "find-dentist-section";

  SNAPSHOT_CARIES_GOOD_BODY = (label: string) =>
    `Your ${label} <b>seem</b> to show there is nothing requiring immediate attention. You should continue with their current oral hygiene routine and schedule routine checkups and cleaning appointments every six months to maintain their oral health. It is still important to see a dentist regularly, as dental findings are not always visible in X-rays.`;
  SNAPSHOT_CARIES_GOOD_SUBHEAD_WITH_CARIES = (label: string) =>
    `You <b>may</b> have some initial cavities but overall, your ${label} do not show anything that needs immediate attention.`;
  SNAPSHOT_CARIES_GOOD_BODY_WITH_CARIES =
    "These can often be taken care of without a filling. The tooth can be strengthened with the use of fluoride products like toothpaste and rinse. It is recommended that you continue with your current oral hygiene routine and schedule routine checkups and cleaning appointments every six months to maintain your oral health.";

  SNAPSHOT_CARIES_FAIR_SUBHEAD =
    "Your teeth need more attention from a dentist.";
  SNAPSHOT_CARIES_FAIR_BODY = (label: string) =>
    `It <b>seems</b> that there are cavities identified on your ${label} that require more attention from a dentist. It is important to follow up with a dentist to determine the appropriate treatment plan for your specific case.`;

  SNAPSHOT_CARIES_ADVANCED_SUBHEAD =
    "Your teeth need immediate attention from a dentist.";
  SNAPSHOT_CARIES_ADVANCED_BODY = (label: string) =>
    `It <b>seems</b> that there are multiple or severe cavities identified on your ${label} that require some immediate attention from a dentist. It is important to follow up with a dentist to determine the appropriate treatment plan for your specific case.`;

  SNAPSHOT_INTRAORAL_LOW_RISK_BODY =
    "Your photos indicating a Low Risk category suggest minimal dental concerns, with teeth and gums appearing healthy and well-maintained. This status typically involves no immediate signs of decay, gum disease, or significant oral health issues. Preventative measures such as regular brushing, flossing, and routine dental check-ups are recommended to maintain oral health. No immediate treatment is necessary, but continued monitoring is advised to prevent potential future issues.";
  SNAPSHOT_INTRAORAL_MEDIUM_RISK_BODY =
    "In the Medium Risk category, photos reveal signs that may indicate the beginning stages of dental concerns, such as early decay and gum inflammation. While these issues might not require urgent treatment, they signal the need for improved oral hygiene practices, possibly alongside professional dental interventions. Regular monitoring and a visit to the dentist are recommended to prevent progression into more serious conditions.";
  SNAPSHOT_INTRAORAL_HIGH_RISK_BODY =
    "Photos classified under High Risk show significant dental issues requiring immediate attention. This includes advanced decay, severe gum disease or substantial tooth damage. Immediate treatment is crucial to mitigate pain, prevent further damage, or infections, and restore oral health. A comprehensive dental examination is recommended to determine the specific treatments needed. Delay in addressing these concerns can lead to more severe health issues and complications.";

  BONELOSS_SNAPSHOT_GOOD_SUBHEAD =
    "The condition on your bone <b>looks like</b> it is in great shape. There is nothing that requires immediate attention or action.";
  BONELOSS_SNAPSHOT_GOOD_BODY =
    "You should continue with their current oral hygiene routine and schedule routine checkups and cleaning appointments every six months to maintain their oral health. It is still important to see a dentist regularly, as dental findings are not always visible in X-rays.";
  BONELOSS_SNAPSHOT_FAIR_BODY =
    "It <b>seems</b> like there are areas of your bone that are not at the levels we expect them to be. There are different risk factors that could cause this to happen including having tartar build up, smoking, or previous dental restorations.";
  BONELOSS_SNAPSHOT_ADVANCED_BODY =
    "The overall condition of the bone is not looking too great. There <b>seems</b> to be areas of your bone that have a history of accelerated bone loss. There are different risk factors that could cause this to happen including having tartar build up, smoking, or previous dental restorations.";

  FINDING_DESCRIPTION_INFO: Partial<
    Record<
      FindingType,
      {
        stage: string;
        title: string;
        infoText: string;
      } | null
    >
  > = {
    [FindingType.Plaque]: {
      stage: "advanced",
      title: "Plaque",
      infoText:
        "Plaque is a sticky film of bacteria that forms on the teeth. It is a colorless and sticky substance that continuously forms on the teeth and gums. If not removed through proper oral hygiene practices such as brushing and flossing, plaque can harden and turn into tartar, which can lead to tooth decay and gum disease.",
    },
    [FindingType.GumRecession]: {
      stage: "advanced",
      title: "Gum Recession",
      infoText:
        "Gum recession is where the gum tissue that surrounds and supports the teeth wears away or pulls back, exposing the roots of the teeth. Gum recession can lead to tooth sensitivity, an increased risk of tooth decay, and even tooth loss if left untreated.",
    },
    [FindingType.GumInflammation]: {
      stage: "advanced",
      title: "Gum Inflammation",
      infoText:
        "Gum Inflammation, also known as gingivitis, is a condition where the gums become red, swollen, and can bleed easily. It is typically caused by bacteria accumulating on the teeth and gums. If left untreated, it can lead to a more severe form of gum disease known as periodontitis, which can damage the bones and tissues that support the teeth.",
    },
    [FindingType.MissingTooth]: {
      stage: "advanced",
      title: "Missing Tooth",
      infoText: "",
    },
    [FindingType.Fracture]: {
      stage: "advanced",
      title: "Fracture",
      infoText:
        "A dental fracture refers to a break or crack in a tooth. When a tooth is fractured, it can cause pain, sensitivity, and difficulty while eating or speaking.",
    },
    [FindingType.Calculus]: {
      stage: "advanced",
      title: "Calculus",
      infoText: "",
    },
    [FindingType.Infection]: {
      stage: "advanced",
      title: "Infection",
      infoText: "",
    },
    [FindingType.DefectiveRestoration]: {
      stage: "advanced",
      title: "Defective Restoration",
      infoText: "",
    },
  };

  @ViewChild("scrollFrame", { static: false })
  scrollFrameRef: ElementRef<HTMLDivElement>;
  @ViewChild("pageHeader", { static: false })
  pageHeaderRef: ElementRef<HTMLDivElement>;
  @ViewChild("snapshot") snapshotSectionRef: ElementRef<HTMLDivElement>;
  @ViewChild("cavities") cavitiesSectionRef: ElementRef<HTMLDivElement>;
  @ViewChild("xrayCarousel") xrayCarouselRef: ElementRef<HTMLDivElement>;
  @ViewChild("bonehealth") bonehealthSectionRef: ElementRef<HTMLDivElement>;
  @ViewChild("smilechecklist")
  smileChecklistSectionRef: ElementRef<HTMLDivElement>;
  @ViewChild("heroSection") heroSection: ElementRef<HTMLDivElement>;
  @ViewChild("sections") sectionsRef: ElementRef<HTMLDivElement>;
  @ViewChild("findDentistSection")
  findDentistSectionRef: ElementRef<HTMLDivElement>;
  @ViewChild("searchWidgetContainer")
  searchWidgetContainerRef: ElementRef<HTMLDivElement>;
  @ViewChild("reportImageGeneration")
  reportImageGenerationRef: ElementRef<HTMLDivElement>;
  @ViewChild("questionsSection")
  questionsSectionRef: ElementRef<HTMLDivElement>;

  @Input() patient: KellsPatient;
  private patient$: Observable<KellsPatient> = observeProperty(this, "patient");

  @Input() portal: Portals;
  Portals = Portals;

  @Input() selectedSessionId: string;
  private selectedSessionId$: Observable<string> = observeProperty(
    this,
    "selectedSessionId"
  )
    .pipe(keepDefined(), distinctUntilChanged())
    .pipe(
      tap((selected: string) => {
        this.data.updateSelectedSession(selected);
      })
    );

  @Input() riskFactors: RiskFactor[] | string[];
  private riskFactors$: Observable<RiskFactor[] | string[]> = observeProperty(
    this,
    "riskFactors"
  ).pipe(keepDefined());

  @Input() images: KellsImageBase[];

  @Input() riskLevel: PatientRecommendationRiskLevels;

  @Input() recommendationNote: string;

  @Input() treatments: Treatment[] | undefined;
  private treatments$: Observable<Treatment[] | undefined> = observeProperty(
    this,
    "treatments"
  ).pipe(tap((trtmnts) => console.log("trtmts: ", trtmnts)));

  @Input() fetchPatientId: string | undefined;
  private fetchPatientId$: Observable<string | undefined> = observeProperty(
    this,
    "fetchPatientId"
  );

  @Input() loading = false;
  showButton = false;
  @ViewChild("content") d1: ElementRef;

  // @Outputs
  @Output("onReportDownload") reportDownloadEvent = new EventEmitter<void>();
  @Output("onXraysDownload") xraysDownloadEvent = new EventEmitter<void>();
  @Output("onXrayOpen") xrayOpenEvent = new EventEmitter<void>();
  // end: @Outputs

  // report variables
  isReportDownloading = false;

  private _subs = new SubSink();

  selectedSessionDate: string;

  public readonly selectedSessionDate$ = this.data.selectedSessionDate$.pipe(
    tap((date) => (this.selectedSessionDate = date))
  );

  public readonly patientFullName$ = this.data.selectedPatient$.pipe(
    map((patient) =>
      [patient.firstName, patient.lastName].filter(Boolean).join(" ")
    )
  );

  public readonly patientInitials$ = this.patientFullName$.pipe(
    map((fullName) =>
      fullName
        .split(" ")
        .map((w) => w.charAt(0))
        .join(" ")
    )
  );

  public readonly SessionImageType = ImageType;

  initialContent: any;
  finalContent: any;

  onRecEditorFullKeyUp(event: any) {
    this.initialContent = event;
  }
  saveRecommendation() {
    if (this.initialContent != undefined) {
      this.finalContent = this.initialContent;
      this.store.dispatch(
        updatePatientRecommendation({
          patientId: this.data.patientId,
          update: {
            riskFactors: [], //risks,
            note: this.finalContent as string,
            sessionId: this.selectedSessionId,
          },
        })
      );
      this.note = this.finalContent;
      this.recommendationNote = this.finalContent;
      this.showButton = false;
    }
  }
  note: any;
  ChangeRecommendation() {
    this.showButton = true;
  }

  cancelRecommendation() {
    // this.recommendationNote = this.content;
    console.log(this.recommendationNote, "notes###");
    this.showButton = false;
    this.renderer.setProperty(
      this.d1.nativeElement,
      "innerHTML",
      this.recommendationNote
    );
    this.cd.detectChanges();
  }
  patientInfo$ = this.patient$
    .pipe(
      keepDefined(),
      map((patient) => ({
        Patient: [patient?.firstName, patient?.lastName]
          .filter(Boolean)
          .join(" "),
        DOB: this.formatDateString(patient?.birthday, "MMMM d, y"),
        Providers: "Kells",
        Sex: capitalize(patient.gender ?? "-"),
      }))
    )
    .pipe(pipeLog("Patient"));

  renderedRiskFactors$: Observable<RiskFactor[]> = this.riskFactors$
    .pipe(
      switchMap((riskFactorsOrIds) =>
        forkJoin(
          (riskFactorsOrIds as (RiskFactor | string)[]).map((r) => {
            if (typeof r === "string") {
              return this.riskFactorStore.get(r).pipe(takeLatest());
            }
            return of(r);
          })
        ).pipe(
          map((riskFactors) => riskFactors.filter(Boolean) as RiskFactor[])
        )
      )
    )
    .pipe(pipeLog("RiskFactors"));

  /** Whether we decide to enable or disable to risk factors. */
  showRiskFactorsSection = false;

  /**
   * A stream of treatments used in report.
   *
   * This stream validates treatments provided as the component's input, and
   * removes treatments that are not actually rendered in report, for various
   * reasons.
   *
   * Use this stream instead of `this.treatments` to reference report data.
   */
  treatmentsInReport$ = combineLatest([
    this.treatments$,
    this.selectedSessionId$,
  ])
    .pipe(
      map(([treatments, selectedSessionId]) => {
        if (isNullable(treatments)) return [];
        // only treatments in the selected session will be used as report input data
        return treatments.filter((t) => t.session === selectedSessionId);
      })
    )
    .pipe(pipeLog("treatmentInReport$"));

  selectedToothFinding$: Observable<IndexedFindingsMap> = new Subject<IndexedFindingsMap>();

  isClinicOne = false;
  isLoading = false;
  private isB2COffice$ = this.store.pipe(select(isB2COffice));
  vm$ = combineLatest([this.isB2COffice$]).pipe(
    map(([isB2COffice]) => ({
      isB2COffice,
    }))
  );

  public readonly DEFAULT_SCHEDULE_APPOINTMENT_LINK =
    "https://spectrumhealth.vsee.me/u/KELLS";

  public readonly DEFAULT_SCHEDULE_APPOINTMENT_DESCRIPTION = `Schedule a consultation with one of our licensed dentists to
    discuss any questions or concerns you may have related to your
    oral health report.
  `;

  public readonly DEFAULT_REPORT_LOGO = "/assets/punch/images/kells-logo-2.svg";

  isAetna = false;

  public readonly patientWithOrganizations$ = this.data
    .patientWithOrganizations$;

  public readonly isDataHidden$ = this.patientWithOrganizations$.pipe(
    map((patientWithOrganizations) => isDataHidden(patientWithOrganizations))
  );

  public readonly isEstimatedCostsHidden$ = this.isDataHidden$.pipe(
    map((isDataHidden) => isDataHidden.estimatedCosts)
  );

  public readonly isZocDocHidden$ = this.isDataHidden$.pipe(
    map((isDataHidden) => isDataHidden.zocDoc)
  );

  public readonly isTreatmentsHidden$ = this.isDataHidden$.pipe(
    map((isDataHidden) => isDataHidden.treatments)
  );

  public readonly isTalkToADentistHidden$ = this.isDataHidden$.pipe(
    map((isDataHidden) => isDataHidden.talkToADentist)
  );

  public readonly isProductRecommendationsHidden$ = this.isDataHidden$.pipe(
    map((isDataHidden) => isDataHidden.productRecommendations)
  );

  public readonly treamentPlanDisplayedTableColumns$: Observable<
    TreatmentPlanTableColumnNames[]
  > = this.isEstimatedCostsHidden$.pipe(
    map((isEstimatedCostsHidden) => {
      const hiddenColumns: TreatmentPlanTableColumnNames[] = [];

      if (isEstimatedCostsHidden) {
        hiddenColumns.push(TreatmentPlanTableColumnNames.CostEstimates);
      }

      const displayedColumns = TREATMENT_PLAN_TABLE_COLUMNS.filter(
        (column) => !hiddenColumns.includes(column)
      );

      return displayedColumns;
    })
  );

  public readonly dentistInfoByPatientId$ = combineLatest([
    this.data.dentistInfoByPatientId$,
    this.data.selectedSessionId$,
  ]).pipe(
    map(
      ([dentistInfoByPatientId, selectedSessionId]) =>
        dentistInfoByPatientId?.find(
          (dentistInfo) => dentistInfo.session === selectedSessionId
        )?.provider
    )
  );

  public readonly findingsByImage$ = this.data.reportDataLoadComplete$.pipe(
    map(({ findingsByImage }) => Array.from(findingsByImage?.values() || []))
  );

  public readonly xrays$ = this.findingsByImage$.pipe(
    map((findingsByImage) =>
      findingsByImage.filter(
        (image) => image.imageType === this.SessionImageType.Xray
      )
    )
  );

  public readonly intraoralPhotos$ = this.findingsByImage$.pipe(
    map((findingsByImage) =>
      findingsByImage.filter(
        (image) => image.imageType === this.SessionImageType.Photo
      )
    )
  );

  public readonly hasFindings$ = this.findingsByImage$.pipe(
    map((findingsByImage) =>
      findingsByImage.some((findings) => !!findings.allFindings.length)
    )
  );

  public readonly allFindings$ = this.findingsByImage$.pipe(
    map((findingsByImage: FindingsByImage[]) => {
      if (!findingsByImage.length) {
        return [];
      }
      return findingsByImage.reduce(
        (acc: RenderableFindingBase[], imageFindings: FindingsByImage) => {
          const flatFindings: RenderableFindingBase[] =
            imageFindings.allFindings;
          return acc.concat(flatFindings);
        },
        []
      );
    })
  );

  public readonly findingsFilterItems$ = this.allFindings$.pipe(
    map((allFindings: RenderableFindingBase[]) => {
      const advancedFindingTypes = [
        FindingType.Plaque,
        FindingType.GumRecession,
        FindingType.GumInflammation,
        FindingType.MissingTooth,
        FindingType.Fracture,
        FindingType.Calculus,
        FindingType.Infection,
        FindingType.DefectiveRestoration,
        FindingType.Caries,
      ];
      const allTypes = allFindings
        .map((findings) => findings.type)
        .filter((findingType) =>
          advancedFindingTypes.some((type) => type === findingType)
        );
      const types = [...new Set(allTypes)];
      const displayableTypes = types.map((type) => {
        return {
          type,
          name: FindingTypeLabels[type] ?? "",
        };
      });
      return displayableTypes;
    })
  );

  selectedFindingsFilterType: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );
  selectedFindingsFilterType$ = this.selectedFindingsFilterType
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());

  public readonly hasXraysCavities$ = this.xrays$.pipe(
    map((xrays) => xrays.some((xray) => !!xray.allCaries.length))
  );

  public isXraysExist = false;

  public readonly isXraysExist$ = this.xrays$.pipe(
    map((xrays) => !!xrays.length),
    tap((isXraysExist) => (this.isXraysExist = isXraysExist))
  );

  public isIntraoralPhotosExist = false;

  public readonly isIntraoralPhotosExist$ = this.intraoralPhotos$.pipe(
    map((intraoralPhotos) => !!intraoralPhotos.length),
    tap(
      (isIntraoralPhotosExist) =>
        (this.isIntraoralPhotosExist = isIntraoralPhotosExist)
    )
  );

  public isIntraoralPhotosFindingsExist = false;

  public readonly isIntraoralPhotosFindingsExist$ = this.intraoralPhotos$.pipe(
    map(
      (intraoralPhotos) =>
        !!intraoralPhotos.some((photo) => !!photo.allFindings.length)
    ),
    tap(
      (isIntraoralPhotosFindingsExist) =>
        (this.isIntraoralPhotosFindingsExist = isIntraoralPhotosFindingsExist)
    )
  );

  public readonly isCariesSectionExists$ = combineLatest([
    this.isXraysExist$,
    this.isIntraoralPhotosExist$,
    this.data.totalCariesSession$,
  ]).pipe(
    map(
      ([isXraysExist, isIntraoralPhotosExist, totalCariesSession]) =>
        isXraysExist || (isIntraoralPhotosExist && totalCariesSession !== 0)
    )
  );

  public readonly organizationBasedContent$ = this.patientWithOrganizations$.pipe(
    map(({ organizations }) => {
      const organization = organizations?.[0].toLowerCase();
      const organizationContent =
        ORGANIZATION_CONTENT_SETTINGS[organization as OrganizationNames];

      return {
        reportLogo: organizationContent?.reportLogo || this.DEFAULT_REPORT_LOGO,
        scheduleAppointmentLink:
          organizationContent?.scheduleAppointmentLink ||
          this.DEFAULT_SCHEDULE_APPOINTMENT_LINK,
        scheduleAppointmentDescription:
          organizationContent?.scheduleAppointmentDescription ||
          this.DEFAULT_SCHEDULE_APPOINTMENT_DESCRIPTION,
      };
    })
  );

  // Q&A section
  cavitiesAccordionPanels = {
    1: false,
    2: false,
    3: false,
  };

  bonelossAccordionPanels = {
    1: false,
    2: false,
    3: false,
  };

  constructor(
    private riskFactorStore: RiskFactorService,
    private datePipe: DatePipe,
    public report: ReportService,
    public data: DataAccessService,
    private treatmentService: TreatmentService,
    private hostEl: ElementRef<HTMLDivElement>,
    private store: Store<fromOralAnalytics.OralAnalyticsState>,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2,
    public dialog: MatDialog,
    private _sanitizer: DomSanitizer,
    private scroller: ViewportScroller,
    private _renderer2: Renderer2,
    private _zocdocService: ZocDocService,
    private _fileService: FileService,
    private _patientService: PatientService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private _document: Document,
    private analyticsService: AnalyticsService,
    private sharedService: SharedService
  ) {
    this._subs.sink = this.sharedService.isAetna$.subscribe((isAetna) => {
      this.isAetna = isAetna;
    });

    if (!isDefined(this.data.report$)) {
      this.data.initReport();
    }

    if (window.location.toString().indexOf("oral-analytics") !== -1) {
      this.isClinicOne = true;
      this.scrollParent = this.hostEl.nativeElement.parentElement;
    } else {
      this.isClinicOne = false;
    }

    gsap.registerPlugin(
      CSSRulePlugin,
      Draggable,
      TextPlugin,
      ScrollToPlugin,
      ScrollTrigger,
      ExpoScaleEase,
      RoughEase,
      SlowMo
    );
    gsap.registerEffect({
      name: "fade",
      effect: (targets: any, config: any) => {
        return gsap.to(targets, { duration: config.duration, opacity: 0 });
      },
      defaults: { duration: 2 }, //defaults get applied to any "config" object passed to the effect
      extendTimeline: true, //now you can call the effect directly on any GSAP timeline to have the result immediately inserted in the position you define (default is sequenced at the end)
    });
    this.isLoading = true;

    this.isIntraoralPhotosFindingsExist$.subscribe();
  }

  scrollParent: any = window;
  currentSection = "snapshot";
  snapshotBonelossBody: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );
  snapshotBonelossSubhead: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );
  snapshotBonelossBody$ = this.snapshotBonelossBody
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());
  snapshotBonelossSubhead$ = this.snapshotBonelossSubhead
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());

  getScrollParent(node: HTMLElement): HTMLElement | null {
    if (node == null) {
      return null;
    }

    if (node.scrollHeight > node.clientHeight) {
      return node;
    } else {
      if (node.parentElement) {
        return this.getScrollParent(node.parentElement);
      } else {
        return null;
      }
    }
  }
  // scroll(event: any, el: ElementRef<HTMLDivElement>) {
  //   event.stopPropagation();
  //   const topY = el.nativeElement.getBoundingClientRect().top;
  //   gsap.to(this.scrollParent , 1, {
  //     scrollTo: {
  //       y: el.nativeElement,
  //       offsetY: 5,
  //       autoKill: true
  //     },
  //     ease: Power3.easeOut
  //   });

  // }

  getSectionElementById(id: string) {
    switch (id) {
      case this.SNAPSHOT_SECTION_ID:
        return this.snapshotSectionRef?.nativeElement;

      case this.CAVITIES_SECTION_ID:
        return this.cavitiesSectionRef?.nativeElement;

      case this.BONE_HEALTH_SECTION_ID:
        return this.bonehealthSectionRef?.nativeElement;

      case this.SMILE_CHECKLIST_SECTION_ID:
        return this.smileChecklistSectionRef?.nativeElement;

      case this.FIND_A_DENTIST_SECTION_ID:
        return this.findDentistSectionRef?.nativeElement;

      case this.QUESTIONS_SECTION_ID:
        return this.questionsSectionRef?.nativeElement;

      default:
        return null;
    }
  }

  getSectionOffsetTopById(id: string) {
    return this.getSectionElementById(id)?.offsetTop || 0;
  }

  scrollToSection(id: string) {
    if (!this.scrollFrameRef) return;

    const elYPosition = this.getSectionOffsetTopById(id);
    const pageHeaderHeight = this.pageHeaderRef.nativeElement.offsetHeight || 0;
    const scrollPosition = elYPosition - pageHeaderHeight;

    this.scrollFrameRef.nativeElement.scroll({
      top: scrollPosition,
      left: 0,
      behavior: "smooth",
    });
  }

  setActiveSection(event: Event) {
    const section = event.target as HTMLDivElement;
    const pageYOffset =
      section.scrollTop + this.pageHeaderRef.nativeElement.offsetHeight || 0;
    let currentSection = "";

    if (!this.isXraysExist) {
      const isCavitiesSectionExists = this.getSectionElementById(
        this.CAVITIES_SECTION_ID
      );

      if (
        isCavitiesSectionExists &&
        pageYOffset < this.getSectionOffsetTopById(this.CAVITIES_SECTION_ID)
      ) {
        currentSection = this.SNAPSHOT_SECTION_ID;
      } else if (
        isCavitiesSectionExists &&
        pageYOffset >= this.getSectionOffsetTopById(this.CAVITIES_SECTION_ID) &&
        pageYOffset <
          this.getSectionOffsetTopById(this.SMILE_CHECKLIST_SECTION_ID)
      ) {
        currentSection = this.CAVITIES_SECTION_ID;
      } else if (
        !isCavitiesSectionExists &&
        pageYOffset <
          this.getSectionOffsetTopById(this.SMILE_CHECKLIST_SECTION_ID)
      ) {
        currentSection = this.SNAPSHOT_SECTION_ID;
      } else if (
        pageYOffset < this.getSectionOffsetTopById(this.CAVITIES_SECTION_ID)
      ) {
        currentSection = this.SNAPSHOT_SECTION_ID;
      } else if (
        pageYOffset >= this.getSectionOffsetTopById(this.CAVITIES_SECTION_ID) &&
        pageYOffset <
          this.getSectionOffsetTopById(this.SMILE_CHECKLIST_SECTION_ID)
      ) {
        currentSection = this.CAVITIES_SECTION_ID;
      } else if (
        pageYOffset >=
          this.getSectionOffsetTopById(this.SMILE_CHECKLIST_SECTION_ID) &&
        pageYOffset <
          this.getSectionOffsetTopById(this.FIND_A_DENTIST_SECTION_ID)
      ) {
        currentSection = this.SMILE_CHECKLIST_SECTION_ID;
      } else if (
        pageYOffset >=
        this.getSectionOffsetTopById(this.FIND_A_DENTIST_SECTION_ID)
      ) {
        currentSection = this.FIND_A_DENTIST_SECTION_ID;
      }
    } else {
      if (
        pageYOffset < this.getSectionOffsetTopById(this.CAVITIES_SECTION_ID)
      ) {
        currentSection = this.SNAPSHOT_SECTION_ID;
      } else if (
        pageYOffset >= this.getSectionOffsetTopById(this.CAVITIES_SECTION_ID) &&
        pageYOffset < this.getSectionOffsetTopById(this.BONE_HEALTH_SECTION_ID)
      ) {
        currentSection = this.CAVITIES_SECTION_ID;
      } else if (
        pageYOffset >=
          this.getSectionOffsetTopById(this.BONE_HEALTH_SECTION_ID) &&
        pageYOffset <
          this.getSectionOffsetTopById(this.SMILE_CHECKLIST_SECTION_ID)
      ) {
        currentSection = this.BONE_HEALTH_SECTION_ID;
      } else if (
        pageYOffset >=
          this.getSectionOffsetTopById(this.SMILE_CHECKLIST_SECTION_ID) &&
        pageYOffset <
          this.getSectionOffsetTopById(this.FIND_A_DENTIST_SECTION_ID)
      ) {
        currentSection = this.SMILE_CHECKLIST_SECTION_ID;
      } else if (
        pageYOffset >=
        this.getSectionOffsetTopById(this.FIND_A_DENTIST_SECTION_ID)
      ) {
        currentSection = this.FIND_A_DENTIST_SECTION_ID;
      }
    }

    if (currentSection !== this.currentSection) {
      this.currentSection = currentSection;
    }
  }

  isCariesSnapshotReady = false;
  isBonelessSnapshotReady = false;

  initCavitiesSnapshot(
    riskScore: number,
    isIntraoralOny: boolean,
    label: string
  ) {
    if (isIntraoralOny) {
      if (riskScore <= 3) {
        this.snapshotSubhead.next(this.SNAPSHOT_INTRAORAL_LOW_RISK_BODY);
      } else if (riskScore <= 7) {
        this.snapshotSubhead.next(this.SNAPSHOT_INTRAORAL_MEDIUM_RISK_BODY);
      } else {
        this.snapshotSubhead.next(this.SNAPSHOT_INTRAORAL_HIGH_RISK_BODY);
      }
    } else {
      if (riskScore == 0) {
        this.snapshotBody.next(this.SNAPSHOT_CARIES_GOOD_BODY(label));
      } else if (riskScore <= 3) {
        this.snapshotSubhead.next(
          this.SNAPSHOT_CARIES_GOOD_SUBHEAD_WITH_CARIES(label)
        );
        this.snapshotBody.next(this.SNAPSHOT_CARIES_GOOD_BODY_WITH_CARIES);
      } else if (riskScore < 8) {
        this.snapshotSubhead.next(this.SNAPSHOT_CARIES_FAIR_SUBHEAD);
        this.snapshotBody.next(this.SNAPSHOT_CARIES_FAIR_BODY(label));
      } else if (riskScore <= 10) {
        this.snapshotSubhead.next(this.SNAPSHOT_CARIES_ADVANCED_SUBHEAD);
        this.snapshotBody.next(this.SNAPSHOT_CARIES_ADVANCED_BODY(label));
      }
    }

    setInterval(() => {
      this.isCariesSnapshotReady = true;
    }, 500);
  }

  trackImageIdentityByUrl(index: number, item: KellsImageBase) {
    return item.url;
  }

  loadZocDocSearchWidget() {
    const widget = this._zocdocService.loadSearchWidget(
      this._renderer2,
      this.searchWidgetContainerRef
    );

    widget.onload = () => {
      const iframe = this._document.querySelector("iframe");
      // Remove border from iframe
      iframe && iframe.classList.add("zocdoc-iframe");
    };
  }

  ngOnInit() {
    this._subs.sink = this.fetchPatientId$
      .pipe(keepDefined(), distinctUntilDeepValueChanged())
      .pipe(
        map((patientId: string) => {
          this.data.updateSelectedPatient({ id: patientId });
          this._subs.sink = this.data.visibleRecommendation$.subscribe(
            (report) => {
              //<{ report: PatientRecommendation, selectedSession: string, findigsByTooth: Map<string, FindingsByTooth>}>
              //  this.initCariesSnapshot(report!.riskLevel, this.data.totalCariesSession$!.value);
            }
          );
        })
      )
      .subscribe();

    this._subs.sink = this.selectedSessionId$.subscribe();

    this._subs.sink = this.data.reportDataLoadComplete$.subscribe(
      ({ report, findingsByTooth }) => {
        //<{ report: PatientRecommendation, selectedSession: string, findigsByTooth: Map<string, FindingsByTooth>}>
        this._subs.sink = combineLatest([
          this.data.sessionScore$.pipe(take(1)),
          this.isXraysExist$,
          this.isIntraoralPhotosExist$,
        ])
          .pipe(
            map(([score, isXraysExist, isIntraoralPhotosExist]) => {
              const isIntraoralOny = !isXraysExist && isIntraoralPhotosExist;

              if (isXraysExist && isIntraoralPhotosExist) {
                return {
                  score,
                  isIntraoralOny,
                  label: "X-rays and intraoral photos",
                };
              }

              if (isXraysExist && !isIntraoralPhotosExist) {
                return { score, isIntraoralOny, label: "X-rays" };
              }

              return { score, isIntraoralOny, label: "" };
            })
          )
          .subscribe(
            ({
              score,
              isIntraoralOny,
              label,
            }: {
              score: number;
              isIntraoralOny: boolean;
              label: string;
            }) => {
              this.initCavitiesSnapshot(score, isIntraoralOny, label);
            }
          );
      }
    );
    this._subs.sink = this.data.boneLossScore$.subscribe((bonelossScore) => {
      this.initBonlessSnapshot(bonelossScore);
    });
  }

  initBonlessSnapshot(bonelossScore: number) {
    let statusText = "FAIR  (" + bonelossScore + ")";
    if (bonelossScore < 3) {
      this.snapshotBonelossSubhead.next(this.BONELOSS_SNAPSHOT_GOOD_SUBHEAD);
      this.snapshotBonelossBody.next(this.BONELOSS_SNAPSHOT_GOOD_BODY);
      statusText = "GOOD (" + bonelossScore + ")";
    } else if (bonelossScore >= 5) {
      this.snapshotBonelossSubhead.next("");
      this.snapshotBonelossBody.next(this.BONELOSS_SNAPSHOT_ADVANCED_BODY);
      statusText = "POOR  (" + bonelossScore + ")";
    } else {
      this.snapshotBonelossSubhead.next("");
      this.snapshotBonelossBody.next(this.BONELOSS_SNAPSHOT_FAIR_BODY);
    }
    setInterval(() => {
      this.isBonelessSnapshotReady = false;
    }, 500);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);

    this.loadZocDocSearchWidget();
    this._subs.sink = fromEvent(this.scrollFrameRef.nativeElement, "scroll")
      .pipe(debounceTime(100))
      .subscribe((event) => this.setActiveSection(event));
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  openDialog(treatmentPlan: string): void {
    const dialogRef = this.dialog.open(VideoDialogComponent, {
      width: "600px",
      data: { name: treatmentPlan },
    });
  }
  snapshotSubhead: BehaviorSubject<string> = new BehaviorSubject<string>("");
  snapshotSubhead$ = this.snapshotSubhead
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());
  snapshotBody: BehaviorSubject<string> = new BehaviorSubject<string>("");
  snapshotBody$ = this.snapshotBody
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());
  snapshotPost: BehaviorSubject<string> = new BehaviorSubject<string>("");
  snapshotPost$ = this.snapshotPost
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());
  showSnapshotPost = false;

  get patientHealthCondition(): PatientOralHealthCondition | null {
    switch (this.data.formRiskLevel$.value) {
      case 1:
        return PatientOralHealthCondition.Good;
      case 2:
        return PatientOralHealthCondition.Fair;
      case 3:
        return PatientOralHealthCondition.Poor;
    }
    return null;
  }

  private formatDateString(date: Date | string, format = "MMMM d, y") {
    if (isNullable(date)) return "-";
    return this.datePipe.transform(date, format);
  }

  public getFindingDescription(finding: RenderableFindingBase) {
    const advancedFindingTypes = [
      FindingType.Plaque,
      FindingType.GumRecession,
      FindingType.GumInflammation,
      FindingType.MissingTooth,
      FindingType.Fracture,
      FindingType.Calculus,
      FindingType.Infection,
      FindingType.DefectiveRestoration,
    ];

    if (finding.type === FindingType.Caries) {
      switch (finding.stage) {
        case CariesStageTypes.Advanced:
          return {
            stage: "advanced",
            title: this.CARIES_ADVANCED_INFO_TITLE,
            infoText: this.CARIES_ADVANCED_INFO,
          };

        case CariesStageTypes.Moderate:
          return {
            stage: "moderate",
            title: this.CARIES_MODERATE_INFO_TITLE,
            infoText: this.CARIES_MODERATE_INFO,
          };

        case CariesStageTypes.Initial:
          return {
            stage: "initial",
            title: this.CARIES_INITIAL_INFO_TITLE,
            infoText: this.CARIES_INITIAL_INFO,
          };
      }
    } else if (advancedFindingTypes.includes(finding.type)) {
      return this.FINDING_DESCRIPTION_INFO[finding.type];
    } else {
      return null;
    }
  }

  CARIES_TAB_FINDINGS = "CARIES_TAB_FINDINGS";
  CARIES_TAB_XRAY = "CARIES_TAB_XRAY";
  CARIES_TAB_INTRAORAL = "CARIES_TAB_INTRAORAL";

  activeCariesTab: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.CARIES_TAB_FINDINGS
  );
  activeCariesTab$ = this.activeCariesTab
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());

  get cariesToothIllustrationSvg(): string {
    return this._cariesToothIllustrationSvg.getValue();
  }

  _cariesToothIllustrationSvg: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );
  cariesToothIllustrationSvg$ = this._cariesToothIllustrationSvg
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());

  setCariesTab(tabName: string) {
    this.activeCariesTab.next(tabName);
  }
  public activeTooth: BehaviorSubject<FindingsByTooth | null> = new BehaviorSubject<FindingsByTooth | null>(
    null
  );
  activeTooth$ = this.activeTooth.asObservable().pipe(
    keepDefined(),
    filter((t) => t !== null),
    distinctUntilDeepValueChanged()
  );

  activeToothXrayImagesCount = 0;
  activeToothInraoralImagesCount = 0;

  activeToothFindingsImages$ = this.activeTooth$.pipe(
    switchMap((activeTooth) =>
      this.data.findingsByImage$.pipe(
        map((findingsByImage) => {
          if (!findingsByImage) {
            return activeTooth.xrays;
          }
          this.activeToothInraoralImagesCount = 0;
          this.activeToothXrayImagesCount = 0;
          const images = activeTooth.xrays
            .map((url) => {
              const image = findingsByImage.get(url);
              if (!image) return;
              const {
                calculus,
                fracture,
                gumInflammation,
                missingTooth,
                gumRecession,
                infection,
                plaque,
                initial,
                moderate,
                advanced,
              } = image;
              const findings = [
                ...calculus,
                ...fracture,
                ...gumInflammation,
                ...missingTooth,
                ...gumRecession,
                ...infection,
                ...plaque,
                ...initial,
                ...moderate,
                ...advanced,
              ];
              const hasFindingsForSelected = findings.some(
                (finding) => finding.tooth === activeTooth.tooth_id
              );
              if (!hasFindingsForSelected) return;
              if (image.imageType === ImageType.Xray) {
                this.activeToothXrayImagesCount++;
              } else {
                this.activeToothInraoralImagesCount++;
              }

              return url;
            })
            .filter(isDefined);
          if (
            (!this.activeToothXrayImagesCount &&
              this.activeCariesTab.value === this.CARIES_TAB_XRAY) ||
            (!this.activeToothInraoralImagesCount &&
              this.activeCariesTab.value === this.CARIES_TAB_INTRAORAL)
          ) {
            this.setActiveCariesTab(this.CARIES_TAB_FINDINGS);
          }

          return images;
        })
      )
    )
  );

  public readonly activeToothFindings$ = this.activeTooth$.pipe(
    map((activeTooth) => activeTooth.allFindings)
  );

  public readonly activeNonCariesToothFindings$ = this.activeTooth$.pipe(
    map((activeTooth) => {
      return activeTooth.allFindings.filter(
        (finding) => finding.type !== FindingType.Caries
      );
    })
  );

  public readonly activeCariesToothFindings$ = this.activeTooth$.pipe(
    map((activeTooth) => {
      return activeTooth.allFindings.filter(
        (finding) => finding.type === FindingType.Caries
      );
    })
  );

  public readonly activeToothFindingsDescriptions$ = this.activeToothFindings$.pipe(
    map((findings) => {
      const findingsDescription = findings
        .map((finding) => {
          return this.getFindingDescription(finding);
        })
        .filter(Boolean);

      const uniqueFindings = uniqBy(
        findingsDescription,
        (item) => `${item?.stage}_${item?.title}`
      );
      return uniqueFindings;
    })
  );

  public activeToothTitle: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );
  activeToothTitle$ = this.activeToothTitle
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());

  public activeToothTitleOrdn: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );
  activeToothTitleOrdn$ = this.activeToothTitleOrdn
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());

  public activeToothDiagnosis: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );
  activeToothDiagnosis$ = this.activeToothDiagnosis
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());

  public activeToothInfoTitle: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );
  activeToothInfoTitle$ = this.activeToothInfoTitle
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());

  public activeToothInfoText: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );
  activeToothInfoText$ = this.activeToothInfoText
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());

  public activeToothCariesStage: BehaviorSubject<string> = new BehaviorSubject<string>(
    "no"
  );
  activeToothCariesStage$ = this.activeToothCariesStage
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());

  CARIES_SECTION_TITLE_SINGLE = "You may have a cavity";
  CARIES_SECTION_TITLE_MULTIPLE = "You may have multiple cavities";
  public cariesSectionTitle: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );
  public cariesSectionTitle$ = this.cariesSectionTitle
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());

  CARIES_INITIAL_DIAGNOSIS = `With the proper care you can prevent this from getting bigger. Initial cavities can often be taken care of without a filling.`;
  CARIES_INITIAL_INFO_TITLE = "Initial Cavity";
  CARIES_INITIAL_INFO =
    "An initial cavity is the start of breakdown in the tooth occuring. With proper brushing a flossing habit the breakdown can be reversed.";

  CARIES_MODERATE_DIAGNOSIS =
    "At this point you may start to have sensitivity in the tooth. This is a sign that the tooth may need a filling.";
  CARIES_MODERATE_INFO_TITLE = "Moderate Cavity";
  CARIES_MODERATE_INFO =
    "A moderate cavity is a breakdown that has reached the inner layer of your tooth. Cavities in this section of the tooth mean that you will need a dental filling or crown to replace the damaged tooth.";

  CARIES_ADVANCED_DIAGNOSIS =
    "You may notice food getting stuck in your tooth and pain starting to occur. Your tooth may need immediate attention. Your dentist may recommend a crown and depending on the extent of the cavity it may also need a root canal.";
  CARIES_ADVANCED_INFO_TITLE = "Advanced Cavity";
  CARIES_ADVANCED_INFO =
    "An advanced cavity is breakdown that has occurred in the tooth almost reaching or has reached the innermost portion of the tooth. This will require immediate attention and delaying treatment can lead to infection or tooth loss.";

  toothTreatments = new BehaviorSubject<any[]>([]);
  toothTreatments$ = this.toothTreatments.asObservable();

  setActiveCariesTab = (tab: string) => {
    this.activeCariesTab.next(tab);

    if (this.activeTooth.value) {
      this.setActiveCariesTooth(this.activeTooth.value);
    }
  };

  setActiveCariesTabXRay = (tab: string) => {
    this.setActiveCariesTab(tab);
    this.recordXRayTabClick(tab);
  };

  recordXRayTabClick = (tab: string) => {
    combineLatest([this.data.sessionSummary$])
      .pipe(take(1))
      .subscribe(([summary]) => {
        const event =
          tab === this.CARIES_TAB_INTRAORAL
            ? ReportEvents.cariesIntraoralPhotoTabClick
            : ReportEvents.cariesXRayTabClick;

        this.analyticsService.record(
          event({
            ...summary,
            patient_email: this.patient.email,
            insurance_provider: this.patient.insurance_provider,
            insurance_status: this.patient.insurance_status,
          })
        );
      });
  };

  recordToothClick(tooth: FindingsByTooth) {
    const toothInfo = pick(tooth, [
      "tooth_id",
      "toothAppearances",
      "totalCaries",
      "qtyAdvanced",
      "qtyInitial",
      "qtyModerate",
      "qtyMaterial",
      "qtyBoneLoss",
    ]);

    combineLatest([this.data.sessionSummary$])
      .pipe(take(1))
      .subscribe(([summary]) => {
        this.analyticsService.record(
          ReportEvents.cariesToothClick({
            ...summary,
            ...toothInfo,
            patient_email: this.patient.email,
            insurance_provider: this.patient.insurance_provider,
            insurance_status: this.patient.insurance_status,
          })
        );
      });
  }
  // cavities Q&A

  setQAndACavitiesExpansionPanelOpened = (
    panelNumber: 1 | 2 | 3,
    isOpened: boolean
  ) => {
    this.cavitiesAccordionPanels[panelNumber] = isOpened;

    // record to analytics on open panel
    if (isOpened) {
      this.recordCavitiesQAndAOpen(panelNumber);
    }
  };

  recordCavitiesQAndAOpen = (panelNumber: 1 | 2 | 3) => {
    combineLatest([this.data.sessionSummary$])
      .pipe(take(1))
      .subscribe(([summary]) => {
        this.analyticsService.record(
          ReportEvents.qaCariesSection({
            ...summary,
            panelNumber,
            patient_email: this.patient.email,
            insurance_provider: this.patient.insurance_provider,
            insurance_status: this.patient.insurance_status,
          })
        );
      });
  };

  setQAndABonelessExpansionPanelOpened = (
    panelNumber: 1 | 2 | 3,
    isOpened: boolean
  ) => {
    this.bonelossAccordionPanels[panelNumber] = isOpened;

    // record to analytics on open panel
    if (isOpened) {
      this.recordQAndABonelessOpen(panelNumber);
    }
  };

  recordQAndABonelessOpen = (panelNumber: 1 | 2 | 3) => {
    combineLatest([this.data.sessionSummary$])
      .pipe(take(1))
      .subscribe(([summary]) => {
        this.analyticsService.record(
          ReportEvents.qaBoneLossSection({
            ...summary,
            panelNumber,
            patient_email: this.patient.email,
            insurance_provider: this.patient.insurance_provider,
            insurance_status: this.patient.insurance_status,
          })
        );
      });
  };

  scheduleAppointmentButtonClick = (
    event: Event,
    scheduleAppointmentLink: string
  ) => {
    event.preventDefault();

    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          title: "You will be taken to our partner’s website now",
          requireConfirmation: true,
        },
        autoFocus: false,
        panelClass: ["reports-modal"],
      })
      .afterClosed()
      .subscribe((confirmed) => {
        if (!confirmed) return;

        window.open(scheduleAppointmentLink, "_blank");
        this.recordScheduleAppointmentClick();
      });
  };

  recordScheduleAppointmentClick = () => {
    combineLatest([this.data.sessionSummary$])
      .pipe(take(1))
      .subscribe(([summary]) => {
        this.analyticsService.record(
          ReportEvents.scheduleAppointmentClick({
            ...summary,
            patient_email: this.patient.email,
            insurance_provider: this.patient.insurance_provider,
            insurance_status: this.patient.insurance_status,
          })
        );
      });
  };

  setActiveCariesTooth = (tooth: FindingsByTooth) => {
    this._cariesToothIllustrationSvg.next(
      `/assets/svgs/buccal/SVG/Tooth-${tooth.tooth_id}.svg`
    );

    this.activeTooth.next(tooth);

    this.activeNonCariesToothFindings$
      .pipe(take(1))
      .subscribe((activeNonCariesToothFindings) => {
        if (activeNonCariesToothFindings.length) {
          this._updateCarieTab(tooth.name.toLowerCase());
        } else {
          this.setCariesState(tooth);
        }
      });
    const treatments = tooth.treatments.filter(
      (t) => t.session === tooth.sessionId
    );
    tooth.treatments = treatments;

    const trmnts = this.parseTreatments(tooth);

    this.toothTreatments.next(trmnts);
    this.changeDetectorRef.detectChanges();
  };

  setCariesState(tooth: FindingsByTooth) {
    if (tooth.qtyAdvanced > 0) {
      this._updateCarieTab(
        tooth.name.toLowerCase(),
        "advanced",
        this.CARIES_ADVANCED_DIAGNOSIS
      );
    } else if (tooth.qtyModerate > 0) {
      this._updateCarieTab(
        tooth.name.toLowerCase(),
        "moderate",
        this.CARIES_MODERATE_DIAGNOSIS
      );
    } else if (tooth.qtyInitial > 0) {
      this._updateCarieTab(
        tooth.name.toLowerCase(),
        "initial",
        this.CARIES_INITIAL_DIAGNOSIS
      );
    }
  }

  private parseTreatments = (tooth: FindingsByTooth) => {
    const reportFindings: KellsFinding[] = tooth.allFindings;
    const rows = tooth.treatments
      .filter((t) => {
        return (
          t.tooth === WHOLE_MOUTH_TREATMENT_LABEL ||
          Boolean(
            this.treatmentService.getFindingsForTreatment(t, reportFindings)
              .length
          )
        );
      })
      .map((t) => {
        const priority =
          TREATMENT_PRIORITY_BY_TYPE[t.description as TreatmentTypes];

        return {
          tooth: tooth.name,
          toothLocation: ToothService.formatToothLocation(t.tooth),
          treatmentPlan: this.treatmentService.formatTreatmentDescription(
            t.description
          ),
          costEstimates: this.toCostEstimateCellData(t.description),
          priority,
          priorityDesc: this.treatmentService.getTreatmentPriorityDescription(
            priority
          ),
        };
      });
    const uniqueTreatments = uniqBy(rows, (item) => item?.treatmentPlan);

    return uniqueTreatments.sort(
      (t1, t2) => (t2.priority ?? -1) - (t1.priority ?? -1)
    ); // sort in descending priority
  };

  private toCostEstimateCellData(treatmentDescription: string): string {
    //} ReportContentCell {
    const costEst = this.treatmentService.lookupCostEstimates(
      treatmentDescription
    );
    if (
      isNullable(costEst) ||
      isNullable(costEst.costMin) ||
      isNullable(costEst.costMax)
    ) {
      return "-";
    }
    return `$${costEst.costMin} - $${costEst.costMax}`;
  }

  splitOrdinalsToParts(label: string): string[] {
    let ordn: string | null = null;
    if (label.indexOf("1st") !== -1) {
      ordn = "1st";
    }
    if (label.indexOf("2nd") !== -1) {
      ordn = "2nd";
    }
    if (label.indexOf("3rd") !== -1) {
      ordn = "3rd";
    }
    if (ordn !== null) {
      const labelParts: string[] = label.split(ordn as string);
      return [ordn, labelParts[1]];
    }
    return [label];
  }

  public hasVideo(treatmentPlanName: string): boolean {
    const videoList = this.report.getVideoList();
    const dentistryVideos = videoList?.length ? videoList[0].videos : [];
    const matchedVideo = dentistryVideos.find((video) =>
      video.id.includes(treatmentPlanName.toLowerCase().split(" ").join("_"))
    );

    return !!matchedVideo;
  }

  splitOrdinals(label: string): void {
    const labelParts = this.splitOrdinalsToParts(label);
    if (labelParts.length > 1) {
      this.activeToothTitleOrdn.next("" + labelParts[0]);
      this.activeToothTitle.next(" " + labelParts[1]);
      //return '<span style="font-feature-settings:\"ordn\";">'+ ordn + '</span> ' + labelParts[1];
    } else {
      this.activeToothTitleOrdn.next("");
      this.activeToothTitle.next(labelParts[0]);
    }
  }

  _updateCarieTab(toothName: string, cariesStage?: string, diagnosis?: string) {
    this.splitOrdinals(toothName);
    cariesStage &&
      this.activeToothCariesStage.next(
        cariesStage === "moderate" ? "a " + cariesStage : "an " + cariesStage
      );
    diagnosis && this.activeToothDiagnosis.next(diagnosis);
  }

  // eslint-disable-next-line class-methods-use-this
  imageIdentifier(index: number, image: Image) {
    return image.id;
  }

  loading$ = combineLatest([
    this.data.loading$.pipe(keepDefined(), delay(1000)),
    this.data.reportDataLoadComplete.pipe(keepDefined(), delay(1000)),
  ])
    .pipe(
      map(([isLoading, report]) => {
        return (
          isLoading === true &&
          isDefined(report) &&
          report?.findingsByTooth?.size === 0
        );
      })
    )
    .pipe(startWith(true), pipeLog("LOADING DELAYED"));

  get formattedSelectedDate() {
    return this._patientService.formatSesssionDate(this.selectedSessionDate);
  }
  // Report functionality
  // 1. Mobile browsers are not able correctly generate reports, so button should be available only for desktop browsers
  isReportDownloadAvailable = browser?.platform?.type === "desktop";

  generateXraysZipFolderName(fileName?: string) {
    return `${[this.patient.lastName, this.formattedSelectedDate, fileName]
      .filter(Boolean)
      .join("_")}.zip`;
  }

  generateXraysZipImageFileName(fileName: string) {
    return `${[this.patient.lastName, fileName].join("_")}`;
  }

  getHeight(nativeElement: HTMLElement) {
    return nativeElement.getBoundingClientRect().height;
  }

  getReportHeight(): number {
    const safeShift = 250;
    const pageHeight = this.getHeight(
      this.reportImageGenerationRef.nativeElement
    );
    const findDentistSectionHeight = this.getHeight(
      this.findDentistSectionRef.nativeElement
    );
    const reportHeight = pageHeight - findDentistSectionHeight - safeShift;

    return reportHeight;
  }

  public getImageTypeName(imageType: ImageType | undefined): string {
    switch (imageType) {
      case ImageType.Photo:
        return "photo";

      case ImageType.Xray:
        return "xray";

      default:
        return "";
    }
  }

  async getReportByBrowser() {
    const commonConfig: HtmlToImageOptions = {
      backgroundColor: "#ffffff",
      cacheBust: true,
      filter: (node: HTMLElement) => {
        if (node?.classList?.length) {
          return !node.classList.contains("omit-report-generation");
        }

        return true;
      },
      filterStyleNode: (node: HTMLElement) => {
        if (node?.classList?.length) {
          return !node.classList.contains("omit-report-generation-style");
        }

        return true;
      },
    };
    const browserName = browser?.browser?.name;

    switch (browserName) {
      // Firefox dom node needs to be chunked because of canvas/image height/width max size limitations
      // https://support.mozilla.org/en-US/questions/973667
      case "Firefox": {
        const nodes = document.querySelectorAll(
          ".report-generate-block"
        ) as NodeListOf<HTMLElement>;

        // Firefox blocks thread and loader is displayed with huge delay
        // to fix it we need unblock thread with setTimeout
        await new Promise((resolve) => setTimeout(resolve, 20));

        return toBlobByChunks(Array.from(nodes), {
          ...commonConfig,
          pixelRatio: 1,
        });
      }

      // Safari doesn't display images with toBlob method
      case "Safari": {
        const reportHeight = this.getReportHeight();

        return toSvg(this.reportImageGenerationRef.nativeElement, {
          ...commonConfig,
          height: reportHeight,
        });
      }

      // all other browsers
      default: {
        const reportHeight = this.getReportHeight();

        return toBlob(this.reportImageGenerationRef.nativeElement, {
          ...commonConfig,
          height: reportHeight,
        });
      }
    }
  }

  async generateReport(): Promise<FileBlobData> {
    const report = await this.getReportByBrowser();

    const blobReport =
      report instanceof Blob
        ? report
        : await fetch(report || "").then((res) => res.blob());
    const fileName = this.generateXraysZipImageFileName(
      `${this.formattedSelectedDate}_report`
    );
    const fileNameWithExt = `${fileName}.${this._fileService.getBlobMimeType(
      blobReport
    )}`;
    const reportImageBlobData: FileBlobData = {
      fileBlob: blobReport,
      fileName: fileNameWithExt,
    };

    return reportImageBlobData;
  }

  public getImageFileBlob(image: Image, index: number) {
    const imageId = index + 1;
    const imageType = this.getImageTypeName(image.imageType);
    const imageName = `${this.formattedSelectedDate}_${imageType}_${imageId}`;

    return {
      includeFileExtension: true,
      downloadUrl: image.url,
      fileName: this.generateXraysZipImageFileName(imageName),
    };
  }

  async downloadReport() {
    if (this.isReportDownloading) return;

    this.reportDownloadEvent.emit();
    this.isReportDownloading = true;

    const generatedReport$ = from(this.generateReport()).pipe(
      catchError((error) => {
        console.error("Patient report image generation failed - ", error);
        return of({});
      })
    );
    const images$ = this.data.images$.pipe(
      takeLatest(),
      map((images: Image[]) => {
        const xrayImagesData = images
          .filter((image) => image.imageType === ImageType.Xray)
          .map((image, index) => this.getImageFileBlob(image, index));
        const photoImagesData = images
          .filter((image) => image.imageType === ImageType.Photo)
          .map((image, index) => this.getImageFileBlob(image, index));

        return { xrayImagesData, photoImagesData };
      }),
      switchMap(async ({ xrayImagesData, photoImagesData }) => {
        const imagesBlobData = await this._fileService.getFilesBlobsDataByUrl([
          ...xrayImagesData,
          ...photoImagesData,
        ]);

        return imagesBlobData;
      }),
      catchError((error) => {
        console.error(
          "Images download failed while generating report - ",
          error
        );
        return of([]);
      })
    );

    forkJoin([generatedReport$, images$]).subscribe(([report, images]) => {
      const folderName = this.generateXraysZipFolderName("report");
      const blobFiles = [report, ...images].filter(
        (file) => !isEmpty(file)
      ) as FileBlobData[];

      this._fileService.downloadZip(blobFiles, folderName);

      this.isReportDownloading = false;
    });
  }

  async downloadXrays(imagesData: OnDownloadParams) {
    const { images, imageType } = imagesData;

    this.xraysDownloadEvent.emit();

    const type = this.getImageTypeName(imageType);
    const folderName = this.generateXraysZipFolderName(`${type}s`);
    const data = images.map((image) => ({
      ...image,
      fileName: this.generateXraysZipImageFileName(`${image.fileName}_${type}`),
    }));
    const imagesBlobData = await this._fileService.getFilesBlobsDataByUrl(data);

    this._fileService.downloadZip(imagesBlobData, folderName);
  }
}
