import { Component, EventEmitter, Output } from "@angular/core";
import { LinkParams } from "../../shared/interfaces/link-params.interface";
import { Params } from "@angular/router";
import { AnalyticsService } from "@kells/apis/analytics";

import {
  NavigateToPaymentLink,
  OpenAiPhotoScanEvent,
} from "../../../../../../../../../apps/patient-reporting/src/app/shared/models/analytics/home-events";
import { SubscriptionPlan } from "@kells/interfaces/patient";
import { combineLatest, of } from "rxjs";
import { DataAccessService } from "@kells/apis/data-access";
import { SharedService } from "apps/patient-reporting/src/app/shared/shared.service";
import { map, switchMap, take } from "rxjs/operators";
import { keepDefined } from "@kells/utils/observable/observable-operators";
import { MembershipService } from "@kells/clinic-one/apis";
import { SubSink } from "subsink";

@Component({
  selector: "kpr-product-recommendation-no-recommendations",
  templateUrl: "./product-recommendation-no-recommendations.component.html",
  styleUrls: ["./product-recommendation-no-recommendations.component.scss"],
})
export class ProductRecommendationNoRecommendationsComponent {
  @Output() public openPhotoScanClicked = new EventEmitter<LinkParams>();

  public readonly patientPlan$ = this.data.selectedPatientSubscriptionPlan$;

  public readonly treatmentVerificationLink$ = combineLatest([
    this.patientPlan$,
    this.sharedService.isAetna$,
  ]).pipe(
    map(([plan, isAetna]) => this.getTreatmentVerificationLink(plan, isAetna))
  );

  public readonly patientId$ = this.data.selectedPatient$.pipe(
    map((patient) => patient.id)
  );

  public readonly premiumPlan$ = this.data.products$.pipe(
    map((products) =>
      products.find((product) => product.type === "subscription")
    ),
    map((product) => product),
    keepDefined()
  );

  private _subs = new SubSink();

  constructor(
    private analyticsService: AnalyticsService,
    public readonly data: DataAccessService,
    private sharedService: SharedService,
    private membershipService: MembershipService
  ) {
    this._subs.sink = this.data.getProducts().subscribe();
  }

  openPhotoScanEmmit(route: string[], routeQuery?: Params) {
    this.analyticsService.record(OpenAiPhotoScanEvent({}));
    this.openPhotoScanClicked.emit({ route, routeQuery });
  }

  public getTreatmentVerificationLink(
    plan: SubscriptionPlan,
    isAetna: boolean
  ) {
    if (isAetna) {
      return "https://form.jotform.com/240908138742156";
    }

    return plan === SubscriptionPlan.Premium
      ? "https://form.jotform.com/242393220296152"
      : "https://form.jotform.com/232995196179171";
  }

  public onTreatmentVerificationClick(event: Event) {
    this._subs.sink = combineLatest([
      this.patientId$,
      this.premiumPlan$,
      this.patientPlan$,
    ])
      .pipe(
        take(1),
        switchMap(([patientId, premiumPlan, patientPlan]) => {
          if (patientPlan === SubscriptionPlan.Premium) {
            return of();
          }

          event.preventDefault();

          const currentUrl = window.location.href;
          const url = new URL(currentUrl);
          url.searchParams.set("payment", "success");
          const redirect_url = url.toString();

          return this.membershipService.getProductPaymentLink({
            price_id: premiumPlan.price_id,
            metadata: {
              patient_id: patientId,
            },
            redirect_url,
          });
        })
      )
      .subscribe((paymentLink) => {
        if (typeof paymentLink === "string") {
          this.analyticsService.record(NavigateToPaymentLink({}));
          window.location.href = paymentLink;
        }
      });
  }
}
